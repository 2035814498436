import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function headersDictionaryToString(headers) {
  if (!headers) {
    return '';
  }
  return Object.keys(headers).map((header) => `${header}: ${headers[header]}`).join("\r\n");
}

function headersStringToDictionary(headers) {
  const result = {};
  if (!headers || !headers.trim()) {
    return null;
  }

  headers.trim().split("\n").forEach((header) => {
    const [key, value] = header.split(":");
    if (value) {
      result[key.trim()] = value.trim();
    }
  });

  if (Object.keys(result).length === 0) {
    return null;
  }
  return result;
}

export default function HeadersEditorDialog({params, children, ...props}) {
  const {value} = params;
  const [innerText, setInnerText] = React.useState(children);
  const [open, setOpen] = React.useState(false);
  const [headers, setHeaders] = React.useState(headersDictionaryToString(value));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const newValue = headersStringToDictionary(headers);
    setInnerText(newValue ? Object.keys(newValue).join(", ") : '(none)');
    params.api.setEditCellValue({id: params.id, field: params.field, value: newValue});
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen}>
        {innerText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Headers</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add additional headers to every request. These headers are static and will overwrite any headers with the same name that are set in the request.
          </DialogContentText>
          <TextField
            label="Additional Headers"
            placeholder="My-Header: my-value"
            multiline
            fullWidth
            value={headers}
            onChange={(event) => setHeaders(event.target.value)}
            sx={{ my: 2 }}
            helperText={<>One header per line, in the format <code>Header-Name: Header-Value</code></>}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleSave} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}