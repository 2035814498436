import React from 'react';
import Container from '@mui/material/Container';
import {fetchWebhooks, updateWebhook, updateWebhooks, fetchAddress} from './network/WebhookAccess';
import { Button, Grid, IconButton, Paper, TextField, Typography, Chip, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditToolbar from './EditToolbar';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import HeadersEditorDialog from './HeadersEditorDialog';

function App() {
  const [address, setAddress] = React.useState({ip: '127.0.0.1', port: 80});
  const [webhooks, setWebhooks] = React.useState(null);
  const [metrics, setMetrics] = React.useState(null);

  const columns: GridColDef[] = React.useMemo(
    () => [
    { field: 'enabled', headerName: 'Enabled', width: 80, editable: true, type: 'boolean' },
    { field: 'name', headerName: 'Name', minWidth: 100, maxWidth: 400, width: 150, editable: true },
    { field: 'url', headerName: 'URL', minWidth: 250, width: 300, editable: true, flex: 1},
    { field: 'headers', headerName: 'Extra Headers', minWidth: 200, width: 200, editable: true,
    valueFormatter: (params) => {
        if (params.value) {
          return Object.keys(params.value).join(', ');
        }
        return '';
       },
       renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <HeadersEditorDialog params={params}>
            {params.formattedValue || '(none)'}
          </HeadersEditorDialog>
        );
       },
    },
    { field: 'auth', headerName: 'Auth', width: 100, editable: true,
      valueGetter: (params) => params.row.auth?.enabled,
      valueSetter: (params) => ({...params.row, auth: {...params.row.auth, enabled: params.value}}),
      type: 'boolean'
    },
    { field: 'auth.url', headerName: 'Auth URL', width: 250, editable: true, type: 'string', 
      valueGetter: (params) => params.row.auth?.url,
      valueSetter: (params) => ({...params.row, auth: {...params.row.auth, url: params.value}}),
    },
    { field: 'auth.clientId', headerName: 'Client ID', width: 150, editable: true, type: 'string', 
      valueGetter: (params) => params.row.auth?.clientId,
      valueSetter: (params) => ({...params.row, auth: {...params.row.auth, clientId: params.value}}),
    },
    { field: 'auth.clientSecretEnv', headerName: 'Secret Env', width: 100, editable: true, type: 'string', 
      valueGetter: (params) => params.row.auth?.clientSecretEnv,
      valueSetter: (params) => ({...params.row, auth: {...params.row.auth, clientSecretEnv: params.value}}),
    },
    { field: 'auth.expiry', headerName: 'Expiry', width: 120, editable: true, type: 'number', 
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }

        return `${params.value} seconds`;
      },
      valueGetter: (params) => params.row.auth?.expiry,
      valueSetter: (params) => ({...params.row, auth: {...params.row.auth, expiry: params.value}}),
    },
    
    { field: 'metrics', headerName: 'Metrics', width: 200, editable: false, renderCell: ({row}) => {
      if (metrics[row.url]) {
        const {success, failure} = metrics[row.url];
        return (
          <Stack direction="row" spacing={0.5}>
            <Chip icon={<CheckIcon />} label={success} variant="outlined" color='success'/>
            <Chip icon={<CloseIcon />} label={failure} variant="outlined" color='error' />
          </Stack>
        );
      }
      return '';
    }},
    { field: 'actions', headerName: 'Actions', width: 80, renderCell: ({row}) =>
      (
        <IconButton
          color='error'
          onClick={() => {
            const filteredWebhooks = webhooks.filter(w => w !== row);
            setWebhooks(filteredWebhooks);
            updateWebhooks(filteredWebhooks);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
      editable: false, sortable: false, filterable: false, hideable: false},
  ], [metrics, webhooks]);

  // edit toolbar:
  const [selectedCellParams, setSelectedCellParams] = React.useState(null);
  const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellFocus = React.useCallback((event) => {
    const row = event.currentTarget.parentElement;
    const id = row.dataset.id;
    const field = event.currentTarget.dataset.field;
    setSelectedCellParams({ id, field });
  }, []);

  const cellMode = React.useMemo(() => {
    if (!selectedCellParams) {
      return 'view';
    }
    const { id, field } = selectedCellParams;
    return cellModesModel[id]?.[field]?.mode || 'view';
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = React.useCallback(
    (params, event) => {
      if (cellMode === 'edit') {
        // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
        event.defaultMuiPrevented = true;
      }
    },
    [cellMode],
  );

  // end edit toolbar
  React.useEffect(() => {
    fetchWebhooks().then(({webhooks, metrics}) => {
      setWebhooks(webhooks);
      setMetrics(metrics);
    });
    fetchAddress().then(setAddress);
  }, []);

  const addWebhookCallback = React.useCallback(() => {
    const newWebhookName = 'my_webhook' + webhooks.filter(row => row.name.startsWith('my_webhook')).length || '';
    const newWebhooks = [...webhooks, {name: newWebhookName, url: 'http://', enabled: false, auth: {enabled: false}}];

    setWebhooks(newWebhooks);
    updateWebhooks(newWebhooks)
      .then(() => {
        // apiRef.current.startRowEditMode({ id: newWebhookName });
      });

  }, [webhooks]);

  const webhooksList = React.useMemo(() => {
    if (!webhooks) {
      return null;
    }

    function updateRow(newRow, oldRow) {
      const isExistingName = webhooks.find(w => w.name === newRow.name && w !== oldRow);
      if (isExistingName) {
        return {
          ...newRow,
          name: oldRow.name,
        };
      }
      if (oldRow !== null) {
        updateWebhook(oldRow.name, newRow);
        Object.assign(oldRow, newRow);
        setWebhooks([...webhooks]);
      }
      
      return newRow;
    }

    return (
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          hideFooter
          editMode="row"
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={updateRow}
          onProcessRowUpdateError={(error) => {console.log(error)}}
          onCellKeyDown={handleCellKeyDown}
          cellModesModel={cellModesModel}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: {
              cellMode,
              selectedCellParams,
              setSelectedCellParams,
              cellModesModel,
              setCellModesModel,
            },
            cell: {
              onFocus: handleCellFocus,
            },
          }}
          rows={webhooks}
          columns={columns}
          getRowId={(row) => row.name}
          sx={{border: 0}}
        />
      </div>
    );
  }, [webhooks, handleCellKeyDown, cellModesModel, cellMode, selectedCellParams, handleCellFocus, columns]);

  return (
    <Container maxWidth={false}>
      <Paper sx={{my: 1}}>
        <Typography variant="h5" sx={{py: 2, px: 2}}>Webhook forwarder</Typography>
        <Button sx={{mx: 1}} onClick={addWebhookCallback} startIcon={<AddIcon />}>Add webhook</Button>
        {webhooksList}
        <Grid container sx={{p: 2}} justifyContent="space-between">
          <Grid item xs={4}>
            <TextField
              label="Forwarding address"
              value={`http://${address.ip}:${address.port}/api/forward`}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>        
      </Paper>
    </Container>
  );
}

export default App;
