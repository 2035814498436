export function fetchWebhooks() {
  return fetch('/api/webhooks')
    .then(response => response.json())
    .then(json => json);
}

export function fetchAddress() {
  return fetch('/api/address')
    .then(response => response.json());
}

export function updateWebhooks(webhooks) {
  return fetch('/api/webhooks', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({webhooks})
  });
}

export function updateWebhook(name, webhook) {
  return fetch(`/api/webhooks/${name}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(webhook)
  });
}