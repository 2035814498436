// generated using https://bareynol.github.io/mui-theme-creator/#Chip
// TODO: copy sbux pill button: https://mui.com/material-ui/customization/theme-components/
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
      type: 'light',
      primary: {
        main: '#006241',
      },
      secondary: {
        main: '#00754a',
      },
      background: {
        default: '#f2f0eb',
      },
      warning: {
        main: '#eed350',
      },
      error: {
        main: '#d3705a',
      },
      info: {
        main: '#d4e9e2',
      },
      success: {
        main: '#4caf50',
      },
      // custom ones:
      white: {
        main: '#ffffff',
      },
      green: {
        main: 'green',
      },
      red: {
        main: 'red',
      }
    },
    typography: {
      fontFamily:
        '"SoDoSans-Regular", "Roboto", "Helvetica", "Arial", sans-serif',
      color: '#202020',
      h1: {
        fontSize: '4rem',
        fontFamily: '"Pike-Bold", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      h2: {
        fontSize: '3rem',
      },
      h3: {
        fontSize: '2.6rem',
      },
      button: {
          textTransform: 'none',
      },
      body1: {
        // fontFamily: '"SoDoSans-Light", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      body2: {
        color: '#686868'
      }
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
          fontWeight: 600,
          lineHeight: 1.2,
        },
      },
    },
  };